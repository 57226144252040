@font-face {
  font-family: Heebo;
  src: url(../fonts/Heebo-300-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Heebo;
  src: url(../fonts/Heebo-500-Regular.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Heebo;
  src: url(../fonts/Heebo-700-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Heebo;
  src: url(../fonts/Heebo-900-Black.ttf);
  font-weight: 900;
  font-style: normal;
}